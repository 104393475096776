import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AnimatedHr from '../components/animatedHr'

const AboutUs = () => (
  <Layout>
    <div className="headerImage industrial">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>Industrial Repairs</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>Your company for industrial electronics repairs in Sheffield</p>
          </div>
        </div>
      </div>
    </div>
    <section>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <h2>
              Nova Electronics for industrial electronics repairs in Sheffield{' '}
            </h2>
            <p>
              Nova Electronics give you a fast, reliable and cheaper alternative
              to a manufacturer’s own team. To our electronics engineers,
              working on industrial electrical equipment is a home from home.
              They enjoy the different challenge from domestic repairs. Even
              better for you, we are local to many industrial companies in
              Sheffield. That means we are responsive – and your downtime is
              kept to a minimum.
            </p>

            <h2>
              We repair all types of electronic equipment for local Sheffield
              industry
            </h2>

            <p>
              Nova Electronics repair many types of industrial electronic
              equipment:
            </p>
            <ul>
              <li>Monitors and digital displays</li>
              <li>Power supplies</li>
              <li>AC & DC motor drives</li>
              <li>Programmable Logic Controllers (PLC).</li>
              <li>Printed Circuit Boards (PCBs). </li>
            </ul>
            <p>
              If you can bring a faulty PCB into us, that will be cheaper still.
              We do painstaking diagnostic checks to make sure it will function
              when back in place. Modern factories and workshops have many
              electrical devices. The Nova Electronics team have worked with
              most. So if your equipment is not shown above, still call us – we
              would like to help.{' '}
            </p>
          </div>
          <div className="six columns">
            <div className="serviceBenefits">
              <h3>Nova's principles for pricing</h3>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <p>
                  Nova Electronics have three principles about keeping your
                  repair costs low
                </p>

                <ol>
                  <li>
                    We talk with you about the problem and the likely costs. We
                    won’t hide anything. We are known for our honesty
                  </li>
                  <li>
                    We only do what is necessary to fix the problem – we don’t
                    do “gold-plating”
                  </li>
                  <li>
                    If something else is found or is needed – we will call you
                    first to discuss it.
                  </li>
                </ol>

                <Link to="/contact-us" className="button">
                  Book in your Repair
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUs
